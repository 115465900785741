input {
  &[type=text] {
    text-align: left;
  }

  &[type=date], &[type=number], &[formControlName="loanAmount"], &[formControlName="value-ExteriorNumber"], &[formControlName="value-InteriorNumber"], &[formControlName="exteriorNumber"], &[formControlName="interiorNumber"], &.is-amount {
      text-align: right;
  }

  &[type=radio] {
    accent-color: $c_primary;
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.125em;
    margin-right: 4px;
  }

  &.buy-something {
    color: $c_black;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    width: 120px;

    &.filled, &:focus, &:hover {
      border: 1px solid $c_yellow;
    }
  }
}

.mat-form-field-infix > input[type=text] {
    background-position: left calc(0.375em + 0.1875rem) center;
}

input:disabled, select:disabled {
    background-color: #e9ecef;
}

.form-group {
    .mat-standard-chip {
      border-radius: 0.75rem;
    }

    label {
      margin-bottom: 0;
    }

    input.form-control {
        background-position: right calc(1em + 0.1875rem) center;
        border: 1px solid #ced4da;
        border-radius: 0.75rem;
        height: 38px;
    }

    textarea.form-control {
      border: 1px solid #ced4da;
      border-radius: 0.75rem;
      resize: none;
    }

    .form-floating {
        input, textarea {
            background-color: $c_primary_light;
            border-radius: unset;
            border: unset !important;
            color: $c_black;
            font-size: 1rem;
            font-weight: 600;
            height: 80px;
        }

        textarea {
            border: unset !important;
            height: 200px;
            padding-top: 2.3rem;
            resize: none !important;
        }

        label {
            color: $c_black;
            font-size: 1rem;
            opacity: 1;
        }
    }

    select.form-control, mat-form-field.form-control, mat-form-field.mat-form-field {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        background-image: url("/assets/images/svg/dropdown.svg");
        background-repeat: no-repeat, repeat;
        background-position: right 0.5em top 50%, 0 0;
        background-size: 0.65em auto, 100%;
        border: 1px solid #ced4da;
        border-radius: 0.75rem;
        height: 38px;
        padding: 0.375rem 0.5rem;
        width: 100%;

        .mat-form-field-wrapper {
            .mat-form-field-flex {
                background-color: transparent;
                border-radius: unset;
                padding: 0;

                .mat-form-field-infix {
                    border-top: unset;
                    padding: 0;

                    .mat-form-field-label-wrapper {
                        label.mat-form-field-label {
                            top: 2rem;
                            margin-bottom: 0;
                        }
                    }

                    .mat-select-trigger {
                        .mat-select-value {
                            .mat-select-placeholder, span.mat-select-value-text span {
                                font-size: 0.8125rem;
                                line-height: 2;
                                color: #495057;
                            }
                        }

                        .mat-select-arrow-wrapper {
                            .mat-select-arrow {
                                color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}
