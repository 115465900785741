@import "/src/assets/scss/grids.scss";

.dark {
    color: $c_black !important;
}

.light {
    color: $c_white !important;
}

.primary {
    color: $c_primary_dark !important;
}

.orange_c {
    color: #FFA345;
}

.c_primary_opacity {
    color: #C7BBDD;
}

.orange_light {
    color: #FFC992;

}

h1,
h2,
h3,
h5 {
    color: $c_black;
}

h4 {
    color: $c_primary;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    width: auto;
}

.fSize15 {
  font-size: 1.5rem;
}

span.subtitle {
    display: block;
    font-size: 2rem;
    margin-bottom: 0rem;

    &.black {
        color: $c_black;
    }

    &.dark {
        color: $c_primary_dark;
    }

    &.light {
        color: $c_white;
    }
}

span.text {
    font-size: 75%;

    &.black {
        color: $c_black;
    }

    &.dark {
        color: $c_primary_dark;
    }

    &.light {
        color: $c_white;
    }
}

span.small_text {
    font-size: 1rem;
    margin-bottom: 1rem;

    &.black {
        color: $c_black;
    }

    &.dark {
        color: $c_primary_dark;
    }

    &.light {
        color: $c_white;
    }
}

span.middle_text {
    font-size: 0.85rem;
    margin-bottom: 1rem;

    &.black {
        color: $c_black;
    }

    &.dark {
        color: $c_primary_dark;
    }

    &.light {
        color: $c_white;
    }
}

span.mini_text {
    font-size: 0.75rem;
    margin-bottom: 1rem;

    &.black {
        color: $c_black;
    }

    &.dark {
        color: $c_primary_dark;
    }

    &.light {
        color: $c_white;
    }
}

@media (min-width: $md) {
    span.title_carousel {
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 1rem;
    }

    span.subtittle_carousel {
        line-height: 1rem;
    }
}

.question {
    margin-top: 1rem;

    &:last-child {
        margin-bottom: 1.5rem;
    }
}

.container_script {
    p.script {
        padding-left: 3rem;
        padding-right: 3rem;

        &:first-child {
            margin-top: 1rem;
        }
    }

    &:last-child {
        p.sript {
            margin-bottom: 1.5rem;
            margin-top: 1rem;
        }
    }
}

.condtions{

    font-size: x-small;
    text-decoration: underline;
    cursor: pointer;
}

a.text-link {
    color: $c_primary_dark;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline !important;

    &.black {
      color: $c_black;
    }

    &.white{
        color: #FFFFFF;
        font-weight: 400;
    }

    &.active,
    &.focus,
    &.hover {
        color: $c_primary_dark;
    }
}

.small {
    font-size: 0.5078125rem;
    font-weight: normal;
    line-height: normal;
    vertical-align: bottom;
}

.big {
    font-size: 4rem;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

label {
    display: flex;

    >i {
        margin-left: 0.5rem;
    }
}
