@import "/src/assets/scss/variables.scss";

.noti-icon .badge {
  left: 23px;
}

.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.fcYNFc {
  background-color: $c_white !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: $c_white;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: $c_secondary !important;
}

/////***** ALENDER CUSTOM STYLES *****/////
.container_title {
  align-items: center;
  display: inline-flex;
  margin-bottom: 1.5rem;

  img {
    height: 48px;
  }

  h2 {
    color: $c_primary_dark;
    margin: 0 0 0 3rem;
  }
}

.bg-primary {
  background-color: $c_primary !important;
}

.bg-secondary {
  background-color: $c_secondary !important;
}

.bg-gray {
  background-color: #e8e5ec !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-alternative {
  background-color: #ebd7a1 !important;
  border-radius: 1.5rem;
}

.no-top-margin {
  padding: 0 calc(24px / 2) 0 calc(24px / 2) !important;

  &.no-top {
    margin-top: 0;
  }
}

///// BODY STYLES //////
.bg_landing {
  //background-image: url("/assets/images/background/bg_degradado.webp");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.mask {
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

///// SECTIONS //////
section {
  .title {
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 3.5rem;

    +.subtitle {
      margin-top: 3rem;

      >span {
        font-weight: 600;
      }
    }

    +.text {
      margin-top: 1.5rem;
    }
  }

  .subtitle {
    font-size: 2rem;
    font-weight: 600;

    +.text {
      margin-top: 1.5rem;
    }
  }

  .subtitle-index {
    font-size: 1.2rem;
    font-weight: 600;

    ::before {
      content: "";
      position: absolute;
      background-color: $c_yellow_opacity;
      bottom: 98px;
      z-index: -1;
      height: 14px;
      width: 324px;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (min-width: $md) {
      ::before {
        left: 9.5rem;
      }
    }

    +.text {
      margin-top: 1.5rem;
    }
  }

  p {
    font-size: 1rem;
    margin-bottom: 0;

    &.text {
      font-size: 1.2rem;
    }

    &.transparent {
      color: transparent;
    }
  }

  @media (max-width: 600px) {
    .title {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.5rem;

      +.subtitle {
        margin-top: 3rem;
      }

      +.text {
        margin-top: 1.5rem;
      }
    }

    .subtitle {
      font-size: 1.5rem;
      font-weight: 600;

      +.text {
        margin-top: 2rem;
      }
    }
  }
}

///// FOOTER STYLES //////
.footer {
  background-color: #FFD88A;
  height: auto;
  padding: 0;
  position: relative;

  section {
    .container-fluid {
      position: relative;
      z-index: 1;
    }

    .mask {
      background-image: url("/assets/images/newImages/ala5.svg");
      background-position: bottom right;
      margin-top: 2rem;
    }
  }

  p {
    color: $c_black;
    margin-bottom: 1rem;

    &.title {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: unset;

      +.text {
        margin-top: unset;
      }
    }

    &.subtitle {
      font-weight: 600;
      font-size: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.text {
      font-weight: normal;
      font-size: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      width: 180px;
    }
  }

  a {
    color: $c_black;

    i {
      font-size: 2rem;
    }
  }
}

//////// Validation input ///////////
.form-group {
  input {

    &.form-control.is-invalid,
    &.mat-form-field-invalid,
    &.was-validated .form-control:invalid {
      background-image: none !important;
      border-color: red !important;
    }
  }

  select {
    &.form-control.is-invalid {
      border-color: red !important;
    }
  }

  mat-form-field {
    &.mat-form-field-invalid {
      border-color: red !important;
    }
  }
}

i.input_icon {
  font-size: 1rem;
  margin-right: 1.8rem;
  padding: 0.6rem;
  position: absolute;
  right: 0;
}

//////// Cards ///////////
.card {
  border-radius: 1rem;

  &.no_bg {
    background-color: unset;
    border: 1px solid $c_gray;
    box-shadow: unset;

    &:last-child {
      margin-bottom: 0;
    }

    .card-body {
      >*:last-child {
        display: none;
      }
    }
  }

  .card-body {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.button-help {
  font-size: 21px;
  cursor: pointer;
  color: $c_primary_dark;
}

////// ICONS  //////
i.bx {
  font-size: 1.2rem;

  &.bx-cart,
  &.bxs-trash-alt {
    cursor: pointer;
    position: relative;
  }

  &.bx-bell {
    cursor: pointer;
  }

  &.bx-check {
    color: $c_success;
  }

  &.bx-error-circle,
  &.bx-x {
    color: $c_warning;
  }

  &.bxs-square {
    &.basic {
      color: $c_gray
    }

    &.another {
      color: $c_primary_soft;
    }

    &.other {
      color: $c_primary_light;
    }

    &.in-progress {
      color: $c_primary_opacity;
    }

    &.complete {
      color: $c_primary_dark;
    }
  }
}

canvas {
  height: auto !important;
  width: 250px !important;

  &.w-full {
    height: unset !important;
    min-width: 80vw !important;
    width: 80vw !important;
  }
}

.card_services {
  a.text_links {
    color: $c_yellow !important;
    font-weight: bold;
    text-decoration: underline !important;
  }

  .custom_card {
    background-color: $c_white;
    border-radius: 1rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 1.5rem 0;
    padding: 1.5rem;

    p {
      &:nth-child(odd) {
        font-weight: bold;
        margin-bottom: 0;
      }
    }

    &.last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: $md) {
    .custom_card {
      padding: 1.5rem 1.5rem 1.5rem 6rem;
    }
  }
}

h2.amount-text {
  min-width: 180px;
  text-align: right;
}

.iframe_map {
  height: 300px;
  width: 100%;
}

@media (min-width: $md) {
  .iframe_map {
    height: 400px;
  }
}

.detail_cart {
  h5 {
    &:first-child {
      padding-right: 0.5rem;
    }

    &:last-child {
      min-width: 120px;
      text-align: right;
    }
  }
}

li+li {
  margin-top: 0 !important;
}

.black {
  color: $c_black;
}

.img_medium {
  height: 400px;
  width: 400px;
}

.img_small {
  cursor: pointer;
  float: left;
  height: 200px;
  margin-right: 10px;
  width: 200px;
}
