.buttons {
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  font-size: 1.375rem;
  font-weight: bold;
  height: 63px;
  justify-content: space-around;
  text-decoration: none;
  width: 293px;

  span {
    display: contents;

    img {
      width: 2.7rem;
    }
  }

  img,
  i {
    width: 30px;
  }

  &.lender-mode {
    background-color: $c_primary_dark;
    border: 1px solid $c_white;
    color: $c_white;
  }

  &.borrower-mode {
    background-color: $c_primary;
    border: 1px solid $c_white;
    color: $c_white;
  }

  &.transparent {
    background-color: transparent;
    border: 1px solid $c_white;
    color: $c_white;
  }

  &.transparent-black {
    background-color: transparent;
    border: 2px solid $c_primary_dark;
    color: $c_primary_dark;
  }

  &.warning {
    background-color: $c_warning;
  }

  &.light {
    background-color: $c_white;
    border: 0px solid $c_black;
    color: $c_primary_dark;
  }

  &.random_btn {
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    font-weight: normal;
    font-size: 14px;
    height: 36px;
    justify-content: space-around;
    margin-left: 1rem;
    text-decoration: none;
    width: 120px;

    &.light {
      background-color: $c_white;
      border: 1px solid $c_black;
      color: $c_black;
    }

    &.golden {
      background-color: $c_golden;
      color: $c_black;
      margin-left: 1rem;
    }

    img {
      width: 30px;
    }
  }

  &.disabled_btn {
    background-color: #e9ecef !important;
    cursor: default !important;
  }
}

.btn {
  font-size: 0.8125rem !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  background-color: $c_primary !important;
  border-color: $c_primary !important;
  color: $c_white;
  width: auto;

  &.w-custom {
    width: 160px;
  }

  &.internal-btn,
  &.external-btn {
    width: 100px;
  }
}

.btn-white,
.btn-white:hover,
.btn-white:focus {
  background-color: $c_white !important;
  border-color: $c_primary_dark !important;
  color: $c_primary_dark;
  width: auto;

  &.internal-btn,
  &.external-btn {
    width: 100px;
  }
}

.float-end .btn.btn-primary,
.content-full .btn.btn-primary {
  width: 160px;
}

.col-md-3.mb-3 {
  .btn-outline-primary {
    &.internal-btn {
      margin-left: unset;
      margin-top: unset;
    }
  }
}

.btn-outline-primary {
  background-color: $c_white !important;
  border-color: $c_primary !important;
  color: $c_primary !important;
  width: 160px;

  &.btn-block {
    width: auto;
  }

  &.internal-btn {
    margin-left: 10px;
    margin-top: 20px;
  }

  &.internal-btn,
  &.external-btn {
    width: 100px;
  }

  &:hover,
  &:focus {
    background-color: $c_primary_opacity !important;
    border-color: $c_primary_opacity !important;
    color: $c_white !important;
  }
}

.btn-outline-secondary,
.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
  background-color: $c_white !important;
  border-color: $c_secondary !important;
  color: $c_secondary !important;
  width: 160px;

  &.btn-block {
    width: auto;
  }

  &:hover,
  &:focus {
    background-color: $c_secondary !important;
    border-color: $c_secondary !important;
    color: $c_white !important;
  }
}

button {

  &.btn_custom,
  &.btn_gray,
  &.btn_primary,
  &.btn_primary_light,
  &.btn_secondary,
  &.btn_yellow,
  &.btn_yellow_light,
  &.btn_primaryOP_light,
  &.btn_random,
  &.btn_outline,
  &.btn_yellow_outline,
  &.btn_primary_outline {
    border: unset;
    border-radius: 10px;
    font-size: 1rem;
    padding: 0;

    &.square {
      border-radius: unset;
      cursor: unset;
    }

    a.nav-link {
      padding: 0.25rem 1rem;

      &.btn-mid {
        padding: 0.75rem 1rem;
      }

      &.btn-big {
        font-size: 1.25rem;
        padding: 0.5rem 2.5rem;
      }
    }

    span.nav-link {
      font-size: 2rem;
      padding: 0 1rem;
    }
  }

  &.btn_custom {
    background-color: $c_white;
    border: $c_black 1px solid;
    border-radius: 5px !important;
    color: $c_black;
    font-size: 1rem !important;
    font-weight: 500;

    &.active,
    &:active,
    &:hover {
      background-color: $c_white;
      border: $c_black 1px solid;
      font-weight: 500;
    }

    &.selected {
      background-color: $c_yellow;
      border: $c_yellow 1px solid;
      color: $c_white;
    }
  }

  &.btn_primary {
    background-color: $c_primary_opacity;
    border: $c_primary_opacity 2px solid;

    &.dark {
      background-color: $c_primary !important;
      border: $c_primary 2px solid !important;
    }

    a.nav-link,
    span.nav-link {
      color: $c_white;
      font-weight: bold;

      &.active,
      &:active,
      &:hover {
        font-weight: bold;
      }
    }
  }

  &.btn_primary_light {
    background-color: $c_primary_soft;
    border: $c_primary_soft 2px solid;

    a.nav-link,
    span.nav-link {
      color: $c_white;
      font-weight: bold;

      &.active,
      &:active,
      &:hover {
        font-weight: bold;
      }
    }
  }

  &.btn_secondary {
    background-color: transparent;
    border: $c_primary_opacity 2px solid;

    a.nav-link,
    span.nav-link {
      color: $c_black;

      &.active,
      &:active,
      &:hover {
        color: $c_black;
      }
    }
  }

  &.btn_yellow {
    background-color: $c_yellow;
    border: $c_yellow 2px solid;
    font-weight: bold;

    a.nav-link,
    span.nav-link {
      color: $c_white;

      &.active,
      &:active,
      &:hover {
        color: $c_white;
      }
    }

    &.mt-3 {
      margin-top: 1.25rem !important;
    }
  }

  &.btn_yellow_light {
    background-color: $c_orange_light;
    border: $c_orange_light 2px solid;
    font-weight: bold;

    a.nav-link,
    span.nav-link {
      color: $c_white;

      &.active,
      &:active,
      &:hover {
        color: $c_white;
      }
    }

    &.mt-3 {
      margin-top: 1.25rem !important;
    }
  }

  &.btn_primaryOP_light {
    background-color: $c_primary_opacity;
    border: $c_primary_opacity 2px solid;
    font-weight: bold;

    a.nav-link,
    span.nav-link {
      color: $c_white;

      &.active,
      &:active,
      &:hover {
        color: $c_white;
      }
    }

    &.mt-3 {
      margin-top: 1.25rem !important;
    }
  }

  &.btn_gray {
    background-color: $c_gray;
    border: transparent 2px solid;
    font-weight: bold;

    a.nav-link,
    span.nav-link {
      color: $c_black;

      &.active,
      &:active,
      &:hover {
        color: $c_black;
      }

      &.line_h {
        line-height: 42.4px;
      }
    }
  }

  &.btn_outline {
    background-color: transparent;
    border: transparent 2px solid;
    font-weight: bold;

    a.nav-link,
    span.nav-link {
      color: $c_black;

      &.active,
      &:active,
      &:hover {
        color: $c_black;
      }

      &.line_h {
        line-height: 42.4px;
      }
    }
  }

  &.btn_primary_outline {
    background-color: transparent;
    border: $c_primary 2px solid;
    font-weight: bold;

    a.nav-link,
    span.nav-link {
      color: $c_black;

      &.active,
      &:active,
      &:hover {
        color: $c_black;
      }

      &.line_h {
        line-height: 42.4px;
      }
    }
  }

  &.btn_yellow_outline {
    background-color: $c_white;
    border: $c_yellow 2px solid;
    font-weight: bold;

    a.nav-link,
    span.nav-link {
      color: $c_black;

      &.active,
      &:active,
      &:hover {
        color: $c_black;
      }
    }
  }
}

.container_button {
  display: none;
  padding: 1.25rem;
}