body.modal-open {
  overflow-y: hidden;

  .modal-container {
    display: block;
    position: relative;
    z-index: 1050;

    .modal {
      display: block;

      .modal-content {
        border: unset;

        .modal-header {
          h5 {
            margin-bottom: 0;
          }
        }

        .modal-body {
          padding: 0;

          img {
            border-radius: 0.4rem;
          }
        }
      }
    }
  }
}

.star {
  cursor: pointer;

  i {
    color: $c_gray;
    font-size: 40px;
  }
}

.star i.filled, .star:hover i {
  color: $c_primary;
}

.rating-box {
  align-items: center;
  background-color: $c_white;
  border: 1px solid $c_black;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  height: 2rem;
  justify-content: center;
  transition: background-color 0.3s;
  width: 2rem;
}


.hovered-rating-box {
  align-items: center;
  background-color: $c_primary; 
  color: $c_white; 
  border: 1px solid $c_black;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  height: 2rem;
  justify-content: center;
  transition: background-color 0.3s;
  width: 2rem;
}


.container_img {
  padding-top: 1.5rem;
  width: 100%;

  img {
    width: 100px;
  }
}
