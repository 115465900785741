@media (min-width: $md) {
  .col-md-5 {
    .card {
      margin-bottom: 0 !important;
    }
  }
}

.col-md-5 {
  .card {
    margin-bottom: 1.5rem;
  }

  &:last-child {
    .card {
      margin-bottom: 0;
    }
  }
}
