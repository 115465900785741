@import "/src/assets/scss/variables.scss";

*,
html,
body {
  font-family: 'Poppins', sans-serif;
}

html,
body {
  background-color: #FAFAFA;
}

html.cdk-global-scrollblock {
  overflow-y: hidden
}

body {
  color: $c_black;
  margin: 0;
}

mat-error.mat-error {
  display: unset !important;
}

.mat-expansion-panel-body {
  padding: 0 24px 24px !important;
}

mat-expansion-panel.mat-expansion-panel {
  background-color: #e8e5ec;
  border-radius: 1rem !important;

  &:not([class*=mat-elevation-z]) {
    box-shadow: unset !important;
  }
}

::ng-deep .mat-horizontal-stepper-header{
  pointer-events: none !important;
}

.mat-expansion-panel-header {
  font-size: 1.2rem !important;

  span.mat-content {
    mat-panel-title.mat-expansion-panel-header-title {
      flex-grow: 100;
    }
  }
}

.mat-form-field-appearance-fill .mat-form-field-label {
  color: #495057 !important;
  font-size: 0.8125rem !important;
  margin-top: -1em !important;
}

.mat-form-field-appearance-fill .mat-form-field-label span.mat-placeholder-required {
  display: none;
}

.header-spacer {
  flex: 1 1 auto;
}

.mat-accordion {
  display: block;
  margin-bottom: 1.5rem;
  width: 100%;

  + *:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  + label, label:not(:last-child), &:last-child {
    margin-bottom: 0;
  }
}

.mat-nav-list .mat-list-item {
  font-size: 1rem;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  justify-content: flex-start;
}

.mat-list-item-content {
  align-items: center;
  justify-content: center;
  display: flex;
}

.mat-select-trigger {
  height: unset;
}

.alender-theme .mat-stepper-horizontal,
.alender-theme .mat-stepper-vertical {
  border-radius: 4px;
}

.nav-tool-items {
  display: inline-block;
  margin-right: 13px;
}

.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
  height: 85px !important;
}

label {
  min-height: unset;

  &.mat-checkbox-layout {
    align-items: baseline;
    display: flex;
    justify-content: end;
    text-align: left;
    white-space: unset !important;

    .mat-checkbox-inner-container {
      margin: 6px 1.5rem auto 0 !important;
    }
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $c_success;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
  background-color: #b0b0b0;
}

.mat-step-header .mat-step-icon-selected {
  background-color: #efb810 !important;
  color: $c_white;

  +.mat-step-label {
    color: red !important;
  }
}

.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: $c_success;

  i.bx.bx-check {
    color: $c_white;
    font-size: 1rem;
    line-height: 21.9px;
  }
}

.mat-radio-button,
.mat-radio-group {
  margin-right: 25px;
}

.controlers-wrapper > * {
  width: 100%;
  padding: 0;
}

.misc-bottom-padding {
  margin: 8px 0 10px;
}

.misc-bottom-padding mat-label {
  margin-right: 15px;
}

mat-radio-group mat-radio-button {
  margin-left: 5px;
}

.button-wrapper button {
  margin-right: 5px;
}

table.mat-table,
table {
  width: 100%;
}

.inner-wrapper {
  padding: 15px 0 130px;
  width: 100%;
}

.inner-wrapper mat-card {
  display: inline-block;
  margin: 0 6% 0 0;
  vertical-align: top;
  width: 44%;
}

mat-grid-tile {
  background: lightblue;
}

@media (max-width: 1024px) {
  .inner-wrapper mat-card {
    width: 100%;
  }

  .misc-bottom-padding mat-label {
    display: block;
    padding-bottom: 10px;
  }

  .mat-nav-list .mat-list-item {
    font-size: 14px;
  }
}

// error snackbar
snack-bar-container.error-snackbar {
  background-color: #C62828 !important;
}

.error-snackbar .mat-simple-snackbar {
  color: white;
}

.error-snackbar .mat-simple-snackbar button {
  color: white;
}

// success snackbar
snack-bar-container.success-snackbar {
  background-color: #43a047 !important;
}

.success-snackbar .mat-simple-snackbar {
  color: white;
}

.success-snackbar .mat-simple-snackbar button {
  color: white;
}

// warning snackbar
snack-bar-container.warning-snackbar {
  background-color: #e65100 !important;
}

.warning-snackbar .mat-simple-snackbar {
  color: white;
}

.warning-snackbar .mat-simple-snackbar button {
  color: white;
}

form > mat-error {
  font-size: 75% !important;
}

.carousel-slide {
  padding-bottom: 0 !important;
  height: 100vh !important;
}

mat-panel-title {
  width: 100%;

  h5 {
    font-weight: 600;
    margin-bottom: 0;
  }

  &.specialSimulator {
    label {
      display: inline-grid;

      .mat-slide-toggle-bar {
        margin-left: 0;
        margin-top: 0.5rem;
        order: 2;

        + span.mat-slide-toggle-content {
          height: 26.39px;
          order: 1;
        }
      }
    }
  }

  &.special {
    label {
      justify-content: start;
    }
  }

  label {
    justify-content: space-between;
  }
}

.full-screen-modal .mat-dialog-container {
  background-color: #FAFAFA;
  max-width: none !important;
}

mat-horizontal-stepper .mat-horizontal-content-container {
  overflow: hidden !important;
  padding: 0 !important;
}

.cdk-overlay-container {
  z-index: 1100 !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.card {
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
    border-radius: 4px;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
  .mat-vertical-stepper-header {
    padding: 5px;
  }
}

.input_filter {
  background-color: $c_white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0 1rem;
  width: 350px;

  .mat-form-field-label-wrapper {
    top: -1.2rem !important;
  }
}

.alender-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.alender-theme .mat-button-toggle-group-appearance-standard {
  border: none !important;
}

.mat-tab-body.mat-tab-body-active {
  mat-expansion-panel.mat-expansion-panel {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: unset;
    }
  }
}

.mat-tab-body-content {
  overflow-x: hidden !important;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

section {
  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      align-items: center;
      display: flex;
      font-size: 2.5rem;
      font-weight: bold;
      color: $c_gray;

      span {
        color: $c_primary_dark;
        font-weight: normal;
        font-size: 1.2rem;
        margin-left: 1.5rem;
      }
    }

    @media (min-width: $sm) {
      li {
        font-size: 4rem;

        span {
          font-size: 1.5rem;
        }
      }
    }

    @media (min-width: $md) {
      li {
        font-size: 6rem;
      }
    }
  }

  .order-first, .order-lg-first {
    order: 2 !important;
  }

  .order-second, .order-lg-second {
    order: 1 !important;
  }

  @media (min-width: $md) {
    .order-first {
      order: 1 !important;
    }

    .order-second {
      margin-bottom: 0;
      order: 2 !important;
    }
  }

  @media (min-width: $lg) {
    .order-lg-first {
      order: 1 !important;
    }

    .order-lg-second {
      order: 2 !important;
    }
  }
}

.mat-drawer {
  transform: unset !important;
}

.mat-drawer-container {
  z-index: unset !important;
}

mat-paginator.mat-paginator {
  border: solid $c_black_light 1px !important;
  border-radius: 0 0 1rem 1rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  .mat-paginator-page-size {
    align-items: center !important;

    mat-form-field.mat-paginator-page-size-select {
      margin: 0 4px;
      top: 5px;
      width: 70px !important;

      .mat-form-field-wrapper {
        top: -10px;

        mat-select.mat-select {
          background-color: transparent;
        }
      }
    }
  }
}

.alender-theme .mat-icon-button.mat-accent {
  color: #43a047 !important;
}

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-thumb {
    background-color: #43a047 !important;
  }

  .mat-slide-toggle-bar {
    background-color: rgb(67, 160, 71, 0.54) !important;
  }
}

.mat-icon-button {
  color: $c_black_opacity;

  &.mat-primary {
    color: $c_primary_dark;
  }
}

.mat-badge-content {
  background-color: red;
}

re-captcha#ngrecaptcha-0 > * {
  transform: scale(0.84);
  transform-origin: 0 0;
}

@media (min-width: 400px) {
  re-captcha#ngrecaptcha-0 > * {
      transform: unset;
      transform-origin: unset;
  }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $c_primary;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $c_primary;
}

/*  Modal */
.cdk-global-overlay-wrapper {
  overflow-y: auto;
  padding: 1.5rem;

  .mat-dialog-container {
    border-radius: 1.5rem;

    .close {
      padding: 0;
      position: relative;
    }
  }
}

.alender-theme .mat-icon-button.mat-proccess {
  color: #FFA345 !important;
}

:host ::ng-deep .mat-horizontal-stepper-header {
  pointer-events: none;
}

:host ::ng-deep .mat-stepper-horizontal-line {
  pointer-events: none;
}
