@import "./grids.scss";
@import "./colors.scss";

mat-tab-group {
    display: flex;
    flex-wrap: wrap;

    mat-tab-header, .mat-tab-body-wrapper {
        padding: 0 0.5rem;
        width: 100%;

        &.mat-tab-header {
            margin-bottom: 1.5rem;
        }

        .mat-tab-label-container {

            .mat-tab-list {
                transform: unset !important;

                .mat-tab-label {
                    border-radius: 0.5rem;
                    opacity: 1;

                    &.mat-tab-label-active {
                        background-color: $c_primary_dark;
                        color: $c_white;
                    }
                }
            }
        }
    }

    p {
        text-align: justify;
    }

    li {
        text-align: justify;
    }

    a {
        cursor: pointer;
    }

    @media (min-width: $md) {
        mat-tab-header {
            flex: 0 0 auto;
            margin-bottom: 0 !important;
            width: 33.33333333%;
        }

        .mat-tab-body-wrapper {
            flex: 0 0 auto;
            width: 66.66666667%;
        }
    }

    @media (min-width: $lg) {
        mat-tab-header {
            flex: 0 0 auto;
            width: 25%;
        }

        .mat-tab-body-wrapper {
            flex: 0 0 auto;
            width: 75%;
        }
    }
}
