@import "/src/assets/scss/grids.scss";
@import "/src/assets/scss/colors.scss";

table {
    &.subscriptionTable {
        thead {
            background-color: $c_white !important;

            th {
                height: 70px;

                .cardContainer {
                    align-items: center;
                    border-radius: 2rem 2rem 0 0;
                    color: $c_white;
                    display: flex;
                    font-size: 1.5rem;
                    height: 100%;
                    justify-content: center;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 3rem;
                    padding-bottom: 1rem;
                    width: 250px;
                }
            }
        }

        @media (max-width: $md) {
            thead {
                display: table-header-group;
            }
        }

        tbody {
            background-color: #e8e5ec !important;

            tr {
                border-style: hidden;

                &:first-child {
                    background-color: $c_white;
                }

                td {
                    &.mat-cell {
                        color: $c_primary_dark;

                        div.textContainer {
                            border-left: solid 1px #DEDEDE;
                            border-right: solid 1px #DEDEDE;
                            margin-left: auto;
                            margin-right: auto;
                            padding: 1rem;
                            width: 250px;
                        }
                    }
                }
            }
        }
    }

    &.evaluation {
        @media (max-width: $md) {
            thead {
                display: table-header-group;
            }
        }

        tbody {
            td {
                &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
                    text-align: center;

                    .mat-radio-button {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    thead {
        th.mat-header-cell {
            &:first-of-type {
                padding-left: unset;
            }

            font-size: 13px;
            text-align: center;
        }
    }

    td.mat-cell {
        padding: 0.75rem 0.75rem;

        i {
            justify-content: center;
            display: flex;
        }
    }
}

.table-responsive {
    border: $c_black_light solid 1px;
    border-radius: 1rem 1rem 0 0;
    border-top: unset;

    table {
        margin-bottom: 0;

        &.bg-primary {
            thead {
                background-color: $c_primary_dark;
            }
        }
        &.bg-secondary {
            thead {
                background-color: $c_primary;
            }
        }

        &.bg-primary, &.bg-secondary {
            thead {
                tr {
                    th {
                        &.mat-header-cell {
                            color: $c_white !important;
                        }
                    }
                }
            }
        }

        > :not(:first-child) {
            border-top: none;
        }

        tr {
            vertical-align: middle;
        }

        thead {
            background-color: $c_gray;
            tr {
                font-size: 12px;
                height: 56px;

                th {
                    padding: 0.25rem 0.75rem !important;
                }
            }
        }

        tbody {
            background-color: $c_white;

            tr {
                td {
                    min-width: 110px;
                    padding: 0.75rem 0.75rem !important;

                    i.bx {
                        cursor: pointer;
                        font-size: 1.5rem;
                    }
                }

                button.mat-button {
                    background-color: transparent !important;
                }

                color: rgba(0, 0, 0, 0.87);
                font-size: 14px;
                height: 48px;

                &:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}
