// Colors
$c_black: #000000;
$c_black_opacity: rgba(0, 0, 0, 0.8);
$c_black_light: rgba(0, 0, 0, 0.2);

$c_blue: #059696;
$c_blue_opacity: rgba(5, 150, 150, 0.8);
$c_blue_light: rgba(5, 150, 150, 0.2);
$c_blue_secondary: rgba(46, 151, 169, 0.8);

$c_gray: #dedede;
$c_gray_opacity: rgba(222, 222, 222, 0.5);

$c_primary: #674B8C;
$c_primary_dark: #3F2A56;
$c_primary_opacity: #8573a8;
$c_primary_soft: #AC9BC6;
$c_primary_light: #DBD3EA;

$c_secondary: #5B6E03;
$c_secondary_opacity: rgba(91, 110, 3, 0.8);
$c_secondary_light: rgba(91, 110, 3, 0.2);

$c_gold: #DDCE6E;
$c_gold_opacity: rgba(221, 206, 110, 0.8);
$c_gold_light: rgba(221, 206, 110, 0.2);

$c_golden: #E9CB31;
$c_golden_opacity: rgba(233, 203, 49, 0.8);
$c_golden_light: rgba(233, 203, 49, 0.2);

$c_yellow: #FFA345;
$c_yellow_opacity: rgba(255, 163, 69, 0.5);
$c_yellow_light: rgba(255, 163, 69, 0.2);

$c_success: #00b74a;

$c_warning: #ff0000;

$c_white: #FFFFFF;
$c_white_opacity: rgba(255, 255, 255, 0.8);
$c_white_light: rgba(255, 255, 255, 0.2);

$c_orange_light:#FFC992;
