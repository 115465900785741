.just_center {
  display: flex;
  justify-content: center;
}

.just_end {
  display: flex;
  justify-content: end;
}

.just_around_nw {
  flex-wrap: nowrap;
  justify-content: space-around;
}
